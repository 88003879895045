<template>
    <footer>
        <div class="c w-xl pt-xxl leading-m">
            <h2>{{ data.footer_title_nl }}</h2>
            <div class="footer__top flex flex-wrap columns color-secondary">
                <div class="w-12 m-up:w-6 xl:w-4 mb-r" v-html="$options.filters.nl2br(data.footer_text_nl)" />
                <div class="leading-l col">
                    Ontdek de mogelijkheden:
                    <br />
                    <div class="flex align-center mb-r">
                        <Button v-if="data.settings && data.settings[0].company_phone" :href="'tel:' + data.settings[0].company_phone" class="weight-r">
                            <template v-slot:icon>
                                <PhoneInTalkIcon :size="20" />
                            </template>
                            {{ data.settings[0].company_phone }}
                        </Button>
                        <Button :to="{ name: 'Contact' }" class="bg-accent">Contact</Button>
                    </div>
                </div>
            </div>

            <div class="footer__bottom flex flex-wrap columns py-xxl leading-l text-nowrap">
                <div class="w-8 s:w-4 m-up:w-4 l-up:w-2 m-down:hide">
                    <router-link to="/" class="logo">
                        <img
                            src="/img/logo_bedrijfsbelang@1x.png"
                            srcset="/img/logo_bedrijfsbelang@1x.png 200w, /img/logo_bedrijfsbelang.png 384w"
                            alt="BedrijfsBelang Logo"
                            title="BedrijfsBelang Logo"
                            class="object-contain"
                            width="195"
                            height="67"
                        />
                    </router-link>
                </div>
                <div v-for="(column, name) in pages" :key="name" class="pr-xxl mr-xxl mb-l">
                    <strong>{{ name }}</strong>
                    <br />
                    <router-link v-for="link in column" :key="link.title" :to="link.route">{{ link.title }}</router-link>
                </div>
                <div v-if="data.settings && data.settings[0].linkedin">
                    <strong>Volg ons</strong>
                    <br />
                    <a :href="data.settings && data.settings[0].linkedin" rel="noreferrer nofollow">
                        <LinkedinIcon :size="28" />
                        <span class="opacity-0">.</span>
                    </a>
                    <div>
                        <br />
                    </div>
                    <a href="https://projectfive.nl" rel="nofollow noreferrer" class="hover:opacity-50 flex align-center size-6 color-secondary">
                        Site door
                        <img src="/img/logo_projectfive.svg" alt="Project Five Logo" title="Project Five" class="w-24 h-24 ml-s" width="24" height="24" />
                    </a>
                </div>
            </div>
        </div>
        <img src="/img/corner_bedrijfsbelang.svg" alt="BedrijfsBelang Logo Icon" title="BedrijfsBelang Logo Icon" class="logo__corner" />
    </footer>
</template>

<script>
import LinkedinIcon from 'vue-material-design-icons/Linkedin.vue';
import PhoneInTalkIcon from 'vue-material-design-icons/PhoneInTalk.vue';

export default {
    name: 'Footer',
    components: {
        LinkedinIcon, PhoneInTalkIcon,
    },
    props: {
        servicePagesWithoutSolar: Array,
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },

        pages() {
            return {
                Diensten: [
                    { title: 'Zonnepanelen', route: '/diensten/1/zonnepanelen' },
                    ...this.servicePagesWithoutSolar,
                ],
                Bedrijf: [
                    { title: 'Over Ons', route: '/over-ons' },
                    { title: 'Projecten', route: '/projecten' },
                    { title: 'Contact', route: '/contact' },
                ],
                'Meer informatie': [
                    { title: 'FAQ', route: '/faq' },
                    { title: 'Algemene voorwaarden', route: '/algemene-voorwaarden' },
                    { title: 'Privacy Policy', route: '/privacy-policy' },
                    { title: 'Klachtenprocedure', route: '/faq/23/ik-heb-een-servicevraag-klacht' },
                ],
            };
        },
    },
};
</script>

<style>
footer {
    overflow: hidden;
    width: 100%;
}
.footer__top.columns {
    --column-gap: var(--size-m);
}
footer .logo img {
    width: 80%;
    display: block;
}
.footer__bottom a {
    display: flex;
}
</style>

import Vue from 'vue';

// Plugins
import axios from 'axios';
import VueLazyload from 'vue-lazyload';
import VueMeta from 'vue-meta';
import webdata from '@/assets/helpers/mixin.webdata';
import nl2br from '@/assets/helpers/filter.nl2br';

// Global Components
import Actions from '@/components/ui/Actions.vue';
import Button from '@/components/ui/Button.vue';
import Card from '@/components/ui/Card.vue';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

// Setup axios
const instance = axios.create({
    baseURL: `https://${process.env.VUE_APP_API_URL}`,
    headers: {
        Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
        namespace: `${process.env.VUE_APP_API_NAMESPACE}`,
    },
    timeout: 15000,
});
Vue.prototype.$http = instance;

Vue.use(VueLazyload, {
    lazyComponent: true,
    observer: true,
});
Vue.mixin(webdata);
Vue.filter('nl2br', nl2br);
Vue.use(VueMeta, {
    keyName: 'meta',
    refreshOnceOnNavigation: true,
});

Vue.component('Actions', Actions);
Vue.component('Button', Button);
Vue.component('Card', Card);

Vue.config.productionTip = false;
Vue.config.ignoredElements = ['tag'];

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');

<template>
    <nav class="py-r" :class="{ '-toggled': toggled }">
        <div class="c w-xxl">
            <div class="flex justify-between l-down:flex-col l-down:align-start xl:align-center">
                <router-link to="/" class="logo">
                    <img
                        src="/img/logo_bedrijfsbelang@1x.png"
                        srcset="/img/logo_bedrijfsbelang@1x.png 200w, /img/logo_bedrijfsbelang.png 384w"
                        alt="BedrijfsBelang Logo" title="BedrijfsBelang Logo" width="195" height="67" />
                    <h1 hidden>BedrijfsBelang</h1>
                </router-link>

                <Button @click="toggled = !toggled" class="bg-accent nav__mobile-toggle absolute xl:hide">
                    <template v-slot:icon>
                        <MenuIcon :size="20" />
                    </template>
                    Menu
                </Button>

                <ul
                    id="nav"
                    class="flex text-nowrap l-down:flex-col l-down:align-stretch l-down:text-center xl:align-center"
                >
                    <li v-for="page in pages" :key="'page_' + page.title">
                        <router-link v-if="page.route" :to="page.route">
                            {{ page.title }}
                        </router-link>
                        <template v-else-if="page.children && page.children.length">
                            <div class="color-secondary cursor-pointer hover:color-primary">
                                {{ page.title }}<span class="color-secondary"><MenuDownIcon v-if="!toggled" :size="20" /></span>
                            </div>
                            <ul>
                                <li v-for="subpage in page.children" :key="'page_' + subpage.title">
                                    <router-link v-if="subpage.route" :to="subpage.route">
                                        {{ subpage.title }}
                                    </router-link>
                                </li>
                            </ul>
                        </template>
                    </li>
                    <li v-if="data.settings && data.settings[0].linkedin">
                        <a :href="data.settings[0].linkedin" rel="noreferrer nofollow">
                            <LinkedinIcon :size="24" />
                        </a>
                    </li>
                    <li class="input-group ml-s">
                        <Button v-if="data.settings && data.settings[0].company_phone" :href="'tel:' + data.settings[0].company_phone" class="weight-r">
                            <template v-slot:icon>
                                <PhoneInTalkIcon :size="20" />
                            </template>
                            {{ data.settings[0].company_phone }}
                        </Button>
                        <Button to="/contact" class="bg-accent">
                            Contact
                        </Button>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import LinkedinIcon from 'vue-material-design-icons/Linkedin.vue';
import MenuDownIcon from 'vue-material-design-icons/MenuDown.vue';
import PhoneInTalkIcon from 'vue-material-design-icons/PhoneInTalk.vue';
import MenuIcon from 'vue-material-design-icons/Menu.vue';

export default {
    name: 'Navigation',
    components: {
        MenuDownIcon, LinkedinIcon, PhoneInTalkIcon, MenuIcon,
    },
    props: {
        servicePagesWithoutSolar: Array,
    },
    data() {
        return {
            toggled: false,
        };
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },

        pages() {
            return [
                { title: 'Zonnepanelen', route: '/diensten/1/zonnepanelen' },
                {
                    title: 'Diensten',
                    children: this.servicePagesWithoutSolar,
                },
                { title: 'Over Ons', route: '/over-ons' },
                { title: 'Projecten', route: '/projecten' },
                { title: 'FAQ', route: '/faq' },
            ];
        },
    },
    watch: {
        '$route.path': {
            handler() { this.toggled = false; },
        },
    },
};
</script>

<style>
    nav { top: 0; z-index: 50; width: 100%; transition: all .3s ease; }
    nav .logo {
        width: 55%;
        max-width: 19.5rem;
    }
        nav .logo img {
            width: 100%;
            display: block;
        }

        #nav > li { position: relative; }
        #nav ul > li > * {
            padding: var(--size-s) var(--size);
            display: block;
        }

        .nav__mobile-toggle {
            right: var(--container-gap);
            top: 1.2rem;
            bottom: 0;
        }

    @media (min-width: 74.9em) {
        #nav > li:not(:hover):not(:focus) > ul { display: none; }
        #nav > li > *:not(.button) {
            padding: 0 var(--size);
        }
        #nav ul {
            top: 100%;
            position: absolute;
            z-index: 10;
            left: 0;
            border-radius: var(--radius-m);
            background: var(--color-card);
            box-shadow: var(--shadow-l);
            padding: var(--size-s) 0 !important;
        }
    }
    @media (max-width: 74.9em) {
        nav.-toggled { background: var(--color-card); box-shadow: var(--shadow-l); }
        #nav { width: 100%; }
        nav:not(.-toggled) #nav {display: none; }
        #nav > li > *:not(.button) {
            padding: var(--size-r) var(--size);
            display: block;
        }
        #nav { margin: var(--size-l) 0 !important; }
        #nav ul { font-size: var(--size-6); }
        nav.-toggled > div #nav > li > ul { display: block; }
        #nav > li.input-group { align-self: center; margin-top: var(--size); }
    }
</style>

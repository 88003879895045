const prerenderSettings = {
    remoteData: {
        texts: '/items/texts/1',
    },
    redirects: {
        default: ['/*   /index.html 200'],
    },
    sitemap: true,
};

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
        meta: {
            prerender: true,
        },
    },
    {
        path: '/over-ons',
        name: 'Over Ons',
        component: () => import(/* webpackChunkName: "article" */ '@/views/Article.vue'),
        meta: {
            article: {
                endpoint: 'items/about/1',
            },
            prerender: {
                url: 'items/about/1',
            },
        },
    },
    {
        path: '/privacy-policy',
        name: 'Privacy',
        component: () => import(/* webpackChunkName: "article" */ '@/views/Article.vue'),
        meta: {
            article: {
                endpoint: 'items/privacy/1',
            },
            prerender: {
                url: 'items/privacy/1',
            },
        },
    },
    {
        path: '/algemene-voorwaarden',
        name: 'Algemene Voorwaarden',
        component: () => import(/* webpackChunkName: "article" */ '@/views/Article.vue'),
        meta: {
            article: {
                endpoint: 'items/terms/1',
            },
            prerender: {
                url: 'items/terms/1',
            },
        },
    },
    {
        path: '/diensten/:id/:slug?',
        name: 'Services',
        component: () => import(/* webpackChunkName: "article" */ '@/views/Article.vue'),
        meta: {
            article: {
                endpoint: 'items/services/[id]',
                schema: (data, context) => [
                    {
                        '@context': 'https://schema.org',
                        '@type': 'Service',
                        serviceType: data.name,
                        description: data.intro_text_nl,
                        image: context.cdnurl(data.intro_image),
                        provider: {
                            '@type': 'Organization',
                            name: 'BedrijfsBelang',
                        },
                        areaServed: {
                            '@type': 'Country',
                            name: 'Netherlands',
                        },
                    },
                ],
            },
            prerender: {
                url: 'items/services/',
                path: '/diensten/[id]/[name|kebabcase]',
            },
        },
    },
    {
        path: '/zonnepanelen',
        redirect: { path: '/diensten/1/zonnepanelen' },
    },
    {
        path: '/gezamenlijk',
        redirect: { path: '/diensten/2/gezamenlijk' },
    },
    {
        path: '/groenenergie',
        redirect: { path: '/diensten/3/groene-energie' },
    },
    {
        path: '/faq/:id/:name?',
        name: 'faq_single',
        props: true,
        component: () => import(/* webpackChunkName: "faq" */ '@/views/FAQSingle.vue'),
    },
    {
        path: '/faq',
        name: 'FAQ',
        component: () => import(/* webpackChunkName: "faq" */ '@/views/FAQ.vue'),
        meta: {
            sitemap: true,
        },
    },
    {
        path: '/projecten/:id/:name?',
        name: 'project_single',
        props: true,
        component: () => import(/* webpackChunkName: "projects" */ '@/views/Project.vue'),
        meta: {
            article: {
                endpoint: 'items/projects/[id]',
                schema: (data, context) => [
                    {
                        '@context': 'https://schema.org',
                        '@type': 'Article',
                        headline: data.name,
                        abstract: data.intro_text_nl,
                        articleBody: data.config_nl.blocks
                            .map((block) => (block.type === 'paragraph'
                                ? block.data.text.replace(/(<([^>]+)>)/gi, '')
                                : ''))
                            .join(''),
                        image: context.cdnurl(data.intro_image),
                        author: {
                            '@type': 'Organization',
                            name: 'BedrijfsBelang',
                            logo: `https://${process.env.VUE_APP_URL}/img/logo_bedrijfsbelang.png`,
                        },
                        publisher: {
                            '@type': 'Organization',
                            name: 'BedrijfsBelang',
                            logo: {
                                '@type': 'ImageObject',
                                url: `https://${process.env.VUE_APP_URL}/img/logo_bedrijfsbelang.png`,
                            },
                        },
                        datePublished: new Date(data.created.timestamp * 1000).toISOString(),
                        dateModified: new Date(
                            (data.edited && data.edited.timestamp
                                ? data.edited.timestamp
                                : data.created.timestamp) * 1000,
                        ).toISOString(),
                        mainEntityOfPage: window.location.href,
                    },
                ],
            },
            prerender: {
                url: 'items/projects/',
                path: '/projecten/[id]/[name|kebabcase]',
            },
        },
    },
    {
        path: '/projecten',
        name: 'projects',
        component: () => import(/* webpackChunkName: "projects" */ '@/views/Projects.vue'),
        meta: {
            sitemap: true,
            schema: (data, context) => [
                {
                    '@context': 'https://schema.org',
                    '@type': 'ItemList',
                    name: 'Projecten',
                    itemListElement: [
                        ...data.map((it, index) => ({
                            '@type': 'ListItem',
                            position: index + 1,
                            url: `/projecten/${it.id}/${context.kebabcase(it.name)}`,
                        })),
                    ],
                },
            ],
        },
    },
    {
        path: '/nieuws/:id/:name?',
        name: 'news_single',
        props: true,
        component: () => import(/* webpackChunkName: "article" */ '@/views/Article.vue'),
        meta: {
            article: {
                endpoint: 'items/news/[id]',
                schema: (data, context) => [
                    {
                        '@context': 'https://schema.org',
                        '@type': 'NewsArticle',
                        headline: data.name,
                        abstract: data.intro_text_nl.replace(/(<([^>]+)>)/gi, ''),
                        articleBody: data.config_nl.blocks
                            .map((block) => (block.type === 'paragraph'
                                ? block.data.text.replace(/(<([^>]+)>)/gi, '')
                                : ''))
                            .join(''),
                        image: context.cdnurl(data.intro_image),
                        author: {
                            '@type': 'Organization',
                            name: 'BedrijfsBelang',
                            logo: `https://${process.env.VUE_APP_URL}/img/logo_bedrijfsbelang.png`,
                        },
                        publisher: {
                            '@type': 'Organization',
                            name: 'BedrijfsBelang',
                            logo: {
                                '@type': 'ImageObject',
                                url: `https://${process.env.VUE_APP_URL}/img/logo_bedrijfsbelang.png`,
                            },
                        },
                        datePublished: new Date(data.created.timestamp * 1000).toISOString(),
                        dateModified: new Date(
                            (data.edited && data.edited.timestamp
                                ? data.edited.timestamp
                                : data.created.timestamp) * 1000,
                        ).toISOString(),
                        mainEntityOfPage: window.location.href,
                    },
                ],
            },
            prerender: {
                url: 'items/news/',
                path: '/nieuws/[id]/[name|kebabcase]',
            },
        },
    },
    {
        path: '/nieuws',
        name: 'nieuws',
        component: () => import(/* webpackChunkName: "news" */ '@/views/News.vue'),
        meta: {
            sitemap: true,
        },
    },
    {
        path: '/contact/succes',
        name: 'ContactSuccess',
        component: () => import(/* webpackChunkName: "contact" */ '@/views/ContactThanks.vue'),
    },
    {
        path: '/contact/:initialmode?',
        name: 'Contact',
        component: () => import(/* webpackChunkName: "contact" */ '@/views/Contact.vue'),
        meta: {
            prerender: true,
        },
        props: true,
    },
    {
        path: '/404',
        name: '404',
        component: () => import(/* webpackChunkName: "e404" */ '@/views/Error404.vue'),
    },
];

exports.routes = routes;
exports.prerenderSettings = prerenderSettings;

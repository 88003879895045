<template>
    <div id="app" class="flex flex-col align-stretch">
        <Navigation :class="['Home', 'project_single'].includes($route.name) ? 'absolute' : 'bg-bg'" :servicePagesWithoutSolar="servicePagesWithoutSolar" />
        <main>
            <router-view/>
        </main>
        <Footer :servicePagesWithoutSolar="servicePagesWithoutSolar" />
        <cookie-law
            buttonText="Oke"
            buttonClass="button -s bg-accent"
        >
            <div slot="message" class="size-6 accentlink">
                Deze website gebruikt cookies voor een optimale ervaring. <router-link to="privacy-policy">Meer info &raquo;</router-link>
            </div>
        </cookie-law>
    </div>
</template>

<script>
import CookieLaw from 'vue-cookie-law';
import Navigation from '@/components/Navigation.vue';
import Footer from '@/components/Footer.vue';
import kebabcase from 'lodash.kebabcase';

export default {
    name: 'App',
    components: {
        Navigation,
        Footer,
        CookieLaw,
    },
    created() {
        if (window.PRERENDER_INJECTED) { // eslint-disable-line
            this.$store.commit('setData', window.PRERENDER_INJECTED.texts); // eslint-disable-line
        } else {
            this.$store.dispatch('get', 'items/texts/1').then((r) => {
                if (r.settings && r.settings[0]) {
                    const settings = r.settings[0];
                    this.$store.commit('setMeta', {
                        from: 'config',
                        data: {
                            descriptionDefault: settings.meta_description_nl,
                            keywordsDefault: (Array.isArray(settings.meta_keywords_nl) ? settings.meta_keywords_nl : []).map((it) => it.name).join(', '),
                        },
                        schemas: [{
                            '@context': 'https://schema.org',
                            '@type': 'Organization',
                            name: settings.company_name,
                            description: settings.meta_description_nl,
                            address: settings.company_address && settings.company_address.parts ? {
                                '@type': 'PostalAddress',
                                addressLocality: settings.company_address.parts.city,
                                postalCode: settings.company_address.parts.zip,
                                streetAddress: `${settings.company_address.parts.street} ${settings.company_address.parts.number}`,
                            } : {},
                            email: settings.company_email,
                            telephone: settings.company_phone,
                            logo: `https://${process.env.VUE_APP_URL}/img/logo_bedrijfsbelang.png`,
                            slogan: settings.meta_title_nl,
                            image: this.cdnurl(r.home_image),
                            url: `https://${process.env.VUE_APP_URL}`,
                            sameAs: [settings.linkedin],
                        }],
                    });
                }
            });
            this.$store.dispatch('get', 'items/projects/random').then((r) => {
                this.$store.commit('storeProject', r);
            });
            this.$store.dispatch('get', { page: 'items/news', params: { count: 3, sort_by: 'id', sort_az: false } }).then((r) => {
                this.$store.commit('storeLatestNews', r);
            });
        }
    },
    meta() {
        return this.$store.getters.meta;
    },
    computed: {
        servicePagesWithoutSolar() {
            const data = this.$store.getters.data;
            if (data && data.services) {
                const otherServices = data.services.filter((it) => it.id !== 1);
                return otherServices.map((it) => ({ title: it.name, route: `/diensten/${it.id}/${kebabcase(it.name)}` }));
            }
            return [];
        },
    },
};
</script>

<style>
@import '~p5-spark-css/dist/forms.css';
@import '~p5-spark-css/dist/vue.css';
@import '~p5-spark-css/dist/base.css';
@import 'assets/css/application.css';
</style>

<template functional>
    <component
        :is="(props.tag || (props.href ? 'a' : (props.to ? 'router-link' : 'button')))"
        :class="'button ' + (data.staticClass || '') + ' ' + (data.class || '')"
        :href="props.href"
        :to="props.to"
        v-bind="data.attrs"
        v-on="listeners"
    >
        <div
            v-if="$scopedSlots.icon"
            class="mr-r flex align-center justify-center"
            :class="data.staticClass.includes('bg-accent') ? 'color-white' : 'color-accent'"
        >
            <slot name="icon" />
        </div>
        <slot />
    </component>
</template>

<script>
export default {
    name: 'Button',
    props: {
        href: String,
        to: [Object, String],
        tag: String,
    },
};
</script>

<style>

</style>
